import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import BaseLayout from '../components/layouts/base-layout';
import { Col, Container, Pagination, Row } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import BlogCard from '../components/atomics/cards/blog-card';
import HeaderLayout from '../components/layouts/header-layout';
import * as style from '../styles/blog.module.scss';

// markup
const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <HeaderLayout/>
      <div style={{padding:'2%'}}>
        <Container className={style.blogHeader}>
            <span className={style.blogTitle}>{t('blog.head')}</span>
        </Container>
      </div>
        
      <Container className={style.blog}>
        <Row>
          <Col md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600', opacity: '0.5' }}>
            {t('blog.coming-soon')}
          </Col>
        </Row>
        {/*<div className="justify-content-center d-flex">

              <Pagination className="text-center mt-4mt-4">
                <Pagination.Prev />
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Item>{4}</Pagination.Item>
                <Pagination.Next />
            </Pagination>
  </div>*/}
      </Container>
    </BaseLayout>
  );
};

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
